import { FaMapMarkerAlt } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Navigate, useNavigate, } from 'react-router-dom';
import { useEffect, useState } from "react";
import { set } from "lodash";

function InquiryDialog({open, onCloseModal, title, currentProduct}) {
     const [submitted, setSubmitted] = useState(false);

     const [enquiry, setEnquiry] = useState('');

     const [submitting, setSubmitting] = useState(false);

     console.log(`currentProduct`, currentProduct);

     const handleInputChange = (e) => {
        setEnquiry(e.target.value);
      }

      useEffect(() => {
        setSubmitted(false);
      setEnquiry('');
        }, [open]);


    const handleSubmit = async () => {
        const selectedProduct = currentProduct;
    
        // const selectedProduct = data.find(product => product.product_slug === props.product_slug);
        const product_id = selectedProduct ? selectedProduct.id : null;
    
        const user = localStorage.getItem('user');
        const userDetailJson = JSON.parse(user);
        const userDetails = userDetailJson.customer_details;
        console.log(`user_details`, userDetails)
    
        const user_id = userDetails.user_id;
    
        const customer_name = `${userDetails.first_name} ${userDetails.last_name}`;
        const customer_email = userDetails.email;
    
        if (!product_id) {
          console.error('Product not found');
          return;
        }
        setSubmitting(true);
        let productDetailUrl = 'https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/productDetails';
        if (selectedProduct.product_slug) {
          productDetailUrl = productDetailUrl + `?product_slug=${selectedProduct.product_slug}`;
        }
    
        const productDetailsResponse = await fetch(productDetailUrl);
        const productDetailJson = await productDetailsResponse.json();
        const productDetails = productDetailJson.data;
    
        console.log(`product_details`, productDetails);
    
        const vendor_id = productDetails.vendor_id;
    
    
        const apiUrl = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/customerenquiries?user_id=${user_id}&vendor_id=${vendor_id}&enquiry=${encodeURIComponent(enquiry)}&product_id=${product_id}&customer_name=${encodeURIComponent(customer_name)}&customer_email=${encodeURIComponent(customer_email)}`;
    
        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            setSubmitted(true);
            setSubmitting(false);

            setTimeout(() => {
              console.log('closing modal');
              onCloseModal();
            }
            , 3000);
    
          })
          .catch((error) => {
            console.error('Error:', error);
            setSubmitting(false);


          });
      }

return <Modal open={open} onClose={onCloseModal} center classNames="bg-black">
{submitted && (
  <div className="p-4 bg-white border-2 border-yellow-500 rounded-lg pb-5 shadow-lg">
    <div className="flex justify-center pb-2">
      <FiCheck className="text-white  bg-green-300 rounded-full  text-4xl  animate-round " />
    </div>
    <p className="text-lg text-center text-yellow-900 font-semibold">
      Thankyou for your query. we&apos;ll contact you shortly
    </p>
  </div>
)}

{!submitted && (
  <><h2 className='inq-title'>Send Inquiry</h2><h3>Tell us about your requirement</h3><h2 className='inq-product-name'>
    Hi, I am interested in {title}.
  </h2><div className='form-right'>

      <div className='form-col'>
        <div className="mt-2">
          <input type="text" name="username" id="username" autocomplete="username" value={enquiry}
            onChange={handleInputChange} className="" placeholder="Write your enquiry" />
        </div>
      </div>

      {/* <div className='form-col'>
<div className="mt-2">
<input type="text" name="username" id="username" autocomplete="username" className="" placeholder="Contact Person"/>
</div>
</div>

<div className='form-col'>
<div className="mt-2">
<input type="text" name="username" id="username" autocomplete="username" className="" placeholder="Mobile Number"/>
</div>
</div> */}


      <div class="form-col"><div class="flex"><div class="mt-2 w-full"><button class="post-btn"
      disabled={submitting}
       onClick={handleSubmit}>{submitting ? 'Submitting...':'Submit'}</button></div></div></div>

    </div></>
)}

</Modal>
}

export default InquiryDialog;