import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ProductList from '../Components/ProductList'
import ProductShow from '../Components/ProductShow'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../Components/Breadcrumb';
import { useLocation } from 'react-router-dom';


function App() {
  const { slug } = useParams();
  console.log(`slug: ${slug}`);
  const location = useLocation();
  const breadcrumb = location.state?.breadcrumb;
  console.log('breadcrumb 16:', location.state);
  const breadcrumbs = [
    // { name: 'Home', path: '/' },
    ...breadcrumb
  ];

  return (
    <div>
      <div>
        <Header />
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div className='mx-auto lg:max-w-7xl lg:px-4 xl:max-w-[1600px]'>
          <ProductList slug={slug} breadcrumbs= {breadcrumbs} />
        </div>
        <Footer />
      </div>
    </div>
  )

}

export default App;
