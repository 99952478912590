import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'react-responsive-modal/styles.css';

function OrderHistoryShow() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const user = localStorage.getItem('user');
  const userDetails = user ? JSON.parse(user) : null;
  console.log('user details', userDetails);
  const token = localStorage.getItem('token');


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userDetails = user ? JSON.parse(user) : null;
    const userId = userDetails?.customer_details ? userDetails?.customer_details.user_id : null;
    const token = localStorage.getItem('token');

    if (!userId) {
      setLoading(false);
      setError({ message: 'Please Login' });
      return;
    }

    let orderHitoryList = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/customerenquirieslist?user_id=${userId}`;

    fetch(orderHitoryList, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setData(data.data.list ?? []);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="enq-container">
    <div className='my-account-sidebar'>
    <ul>
    <li>            
              {token && (
                <button className='px-4 h-10 bg-white text-[#082f4a] font-[400] font-poppins' onClick={handleLogout}>Logout</button>
              )}
        </li>
      </ul>
    </div>

      <ul className='post enq-list-container'>
        {
          data.map((item) => (
            <li key={item.id} className='w-full enq-list-li'>
              <Link to={`/product-details/${item.product_slug}`} className='enq-list flex'> 
                <div className='enq-product-image-box'>
                    <img src={item.featured_image} className='enq-pro-img' alt={item.product_name}/>                  
                </div>
                <div className='enq-product-content'>
                  <h2 className='enq-pro-name'>{item.product_name}</h2>                   
                  <p className='enq-pro-item-enquiry'>{`Enquirey: ${item.enquiry}`}</p>
                  <p className='text-[#082f4a] font-poppins font-normal text-[16px] pb-4'>{item.product_quantity}</p>
                  <p className='text-[#082f4a] font-poppins font-normal text-[16px] pb-4'>{new Date(item.posted_date).toLocaleDateString()}</p>
                  <p className='text-[#082f4a] font-poppins font-normal text-[16px] pb-4'>{item.order_date}</p>
                </div>
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default OrderHistoryShow;
