import React from 'react'

const Footer = () => {
  return (
    <div className='main-footer'>    
    <div className='lg:max-w-7xl lg:px-4 xl:max-w-[1600px] mx-auto mt-4 px-8 pt-4'>
      <div className=' px-12 py-8 mx-auto grid-flow-col  w-full grid grid-col-12'>
   
        <div className='col-span-2'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>Customer Support</h3>
           <ul className='text-white text-[16px]   text-left'>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]' >Help Center</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>User Guide</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Return & Cancellation Policy</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Shipping & Delivery Policy</li>
           </ul>
        </div>  
       
        <div className='col-span-2'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>For Sellers</h3>
           <ul className='text-white text-[16px]   text-left'>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]' >
            <a href="https://femonia.com/makeittomer/vendor-register/">Register for Sellers</a></li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]' >
            <a href="https://femonia.com/makeittomer/">Login for Sellers</a></li>
            </ul>
        </div>          
        <div className='col-span-2'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>For Buyers</h3>
           <ul className='text-white text-[16px]   text-left'>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]' >Post Your Requirements</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Brows Suppliers</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Subcribe Sell Trade Alerts</li>
            </ul>
        </div>   
        <div className='col-span-2'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>Directory</h3>
           <ul className='text-white text-[16px]   text-left'>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]' >Manufactures</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Business Services</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Service Providers</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Industry Hub</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Country Suppliers</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Featured Products</li>
            <li className='text-[#ffffffa8] text-[14px] mb-[4px]'>Sitemap</li>
            </ul>
        </div>  
             
       
      </div>
      <hr className='w-full bg-[#ffffffa8] h-[1px]'/>
      <div className='w-full py-4 mx-auto'>
        <h3 className='text-[#ffffff] text-[16px] text-center'>1999-2024 Made in Timor-leste. All rights reserved.</h3></div>
    </div>
    </div>
  )
}

export default Footer
