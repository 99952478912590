import React from 'react';
import SideBar from './SideBar';
import NavBar from './NavBar';
import rightBanner from '../assests/img/right-banner.jpg';
import banner1 from '../assests/img/image04.png';
import leftBanner from '../assests/img/left-banner.jpg';


import SidebarMenu from './SideMenu';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaBagShopping } from "react-icons/fa6";
import { LiaHandshakeSolid } from "react-icons/lia";
import { GrDomain } from "react-icons/gr";
import { GiBookshelf } from "react-icons/gi";
import { MdCardMembership } from "react-icons/md";
import CategoryList from './CategoryList';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const BannerSection = () => {
  return (
    <div>
      <div className="grid gap-4 grid-cols-12">
        <div className="col-span-2 relative z-[998]">
          <CategoryList/>
        </div>
        <div className='col-span-10 relative z-[0] '>
          <div className='grid grid-cols-12 gap-4'>
      
          <div className="w-full col-span-8 main-top-slider">
            <Carousel responsive={responsive}
             infinite={true}>
              
              <div> <img src={leftBanner} alt="Logo" className="w-full" /></div>
              <div> <img src={leftBanner} alt="Logo" className="w-full" /></div>
            
            </Carousel>
           
          </div>
        
        <div className=' w-full col-span-4 '>

        <div className='right-banner-top'>
        <img src={rightBanner} alt="Logo" />
        </div>
        
       
      
         
        
        </div>
        </div>
       
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
