import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaPhone, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import CategoryList from './CategoryList';
import advt from '../assests/img/advt.jpg';
import InquiryDialog from './InquireyDialog';
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";

function App(props) {
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState({ name: '', description: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [enquiry, setEnquiry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const user = localStorage.getItem('user');
  const userDetails = user ? JSON.parse(user) : null;
  console.log('user details', userDetails);
  const token = localStorage.getItem('token');

  const [currentProduct, setCurrentProductData] = useState();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();





  useEffect(() => {
    let productListUrl = 'https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/productlist';
    if (props.slug) {
      productListUrl = productListUrl + `?category_slug=${props.slug}`;
    }
    //fetch('https://newsapi.org/v2/everything?q=tesla&from=2024-06-21&sortBy=publishedAt&apiKey=0605c7d49a5e42088f7a458e70b581cb')
    fetch(productListUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data:', data); // Log the fetched data
        if (Array.isArray(data.data)) {
          setData(data.data);
          setCategoryData({
            name: data.category_name,
            description: data.category_description
          });
        } else {
          throw new Error('Fetched data is not an array');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [props.slug]);

  const handleProductClick = (product) => {
    console.log('Product clicked:', product);
    console.log('product breadcrumbs:', props.breadcrumbs);
    // navigate('/product-details/' + product.product_slug);

    navigate(`/product-details/${product.product_slug}`, {
      state: {
        breadcrumbs: [...props.breadcrumbs, { name: product.title, path: `/product-details/${product.product_slug}` }],
      }
    });
  }

  // Function to check if the user is logged in
  const checkIfUserIsLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  }

  // Function to handle the enquiry button click
  const handleEnquiryClick = (product) => {
    const isLoggedIn = checkIfUserIsLoggedIn();
    if (isLoggedIn) {
      setCurrentProductData(product);
      onOpenModal();
    } else {
      navigate('/login', { state: { from: '/products/' + props.slug } });

    }
  }



  // const handleSubmit = async () => {
  //   const selectedProduct = currentProduct;
  //   console.log('product_clicked', selectedProduct);

  //   // const selectedProduct = data.find(product => product.product_slug === props.product_slug);
  //   const product_id = selectedProduct ? selectedProduct.id : null;

  //   const user = localStorage.getItem('user');
  //   const userDetailJson = JSON.parse(user);
  //   const userDetails = userDetailJson.customer_details;
  //   console.log(`user_details`, userDetails)

  //   const user_id = userDetails.user_id;

  //   const customer_name = `${userDetails.first_name} ${userDetails.last_name}`;
  //   const customer_email = userDetails.email;

  //   if (!product_id) {
  //     console.error('Product not found');
  //     return;
  //   }
  //   let productDetailUrl = 'https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/productDetails';
  //   if (selectedProduct.product_slug) {
  //     productDetailUrl = productDetailUrl + `?product_slug=${selectedProduct.product_slug}`;
  //   }

  //   const productDetailsResponse = await fetch(productDetailUrl);
  //   const productDetailJson = await productDetailsResponse.json();
  //   const productDetails = productDetailJson.data;

  //   console.log(`product_details`, productDetails);

  //   const vendor_id = productDetails.vendor_id;


  //   const apiUrl = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/customerenquiries?user_id=${user_id}&vendor_id=${vendor_id}&enquiry=${encodeURIComponent(enquiry)}&product_id=${product_id}&customer_name=${encodeURIComponent(customer_name)}&customer_email=${encodeURIComponent(customer_email)}`;

  //   fetch(apiUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log('Success:', data);
  //       setSubmitted(true)

  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // }


  //flash messae after enquiry form is submitted
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCloseModal();
      }
    };
    // if (submitted) {
    //   const timer = setTimeout(() => {
    //     setSubmitted(false);
    //     setEnquiry('')
    //   }, 3000);

    //   return () => clearTimeout(timer);
    // }

    window.addEventListener("keydown", handleKeyDown);
  }, [onCloseModal, submitted]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }



  return (
    <div className='product-container container flex mb-[50px]'>

      <div className='sidebar'>
        <CategoryList />
      </div>


      <div className="left-contant col-span-8">
        <h2 className='category-name-title'>{categoryData.name}</h2>
        <p className='category-description-text'>{categoryData.description}</p>
        {(data.length === 0) && <div className='no-result-found'>No products found</div>}
        <ul className='post'>
          {data.map((data, index) => (
            <li key={index} className='product-grid-box'>
              <div className='col-span-4 flex justify-center product-img-box'>
                <div className='border-2 w-[200px] product-img' onClick={(e) => handleProductClick(data)}>  <img src={data.featured_image} className='w-full' /> </div> </div>
              <div className='col-span-8 px-4 text-left product-detail-left'>
                <div className='prod-info-left'>
                <h3 className='product-title' onClick={(e) => handleProductClick(data)}>{data.title}</h3>
                {!token && (
                  <>
                    <h4 className='price-box'>  <Link to="/login" className='btn btn-latest-price'>Get Latest Price</Link></h4>
                  </>
                )}

                {userDetails && (
                  <h4 className='price-box'>  <div className='btn btn-latest-price'>Rs. {data.regular_price}</div></h4>
                )}
                {data.attribute.length < 1 && (
                  <br></br>
                )}

                <div className="seller-row">

{data.attribute.length > 0 &&
  (
    <div class="attribute-box tabledesc mt10">
      <table><tbody>
        {data.attribute.map((item, index) => (
          <tr><td class="tdwdt">{item.name}</td><td class="tdwdt1 color6"><div class="dsf"><span> {item.option}</span></div></td></tr>

        ))}

      </tbody></table></div>
  )
}






</div>

                </div>

                <div className='prod-info-right'>
                <div className='seller-box'>
                    <a href="#" className='seller-name flex'><img src={data.vendor_details.logo != '' ? data.vendor_details.logo : "https://4.imimg.com/data4/DR/BE/MY-21573024/skyland-metal-and-alloys-inc-90x90.jpg"} /><span>{data.vendor_details.store_name}</span></a>
                    {data.vendor_details.address_1
                      &&
                      data.vendor_details.address_2 &&
                      data.vendor_details.city &&
                      data.vendor_details.state &&
                      data.vendor_details.country &&
                      data.vendor_details.postcode &&


                      <div className='seller-address'>{data.vendor_details.address_1}, {data.vendor_details.address_2}, {data.vendor_details.city}, {data.vendor_details.state}, {data.vendor_details.country}, {data.vendor_details.postcode} </div>}
                    <div className='gst-number'>GST/TIN - {data.vendor_details.tin_number}</div>
                    <div className='trust-seller'><img src="https://www.tradeindia.com/_next/image/?url=%2Fimages%2Ficons%2Ftrusted-seller-new.png&w=1920&q=75" />
                      <span class="trusted-text"><strong>Trusted</strong>Seller</span></div>

                    <div className='contact-seller-row'>
                      <button onClick={(e) => handleEnquiryClick(data)} className='send-inq-btn'>
                      <CiMail />
                      Contact Now</button>
                      {currentProduct && <InquiryDialog open={open} onCloseModal={onCloseModal} title={currentProduct.title} submitted={submitted} currentProduct={currentProduct} />

                      }
                      {/* <button className='view-number-btn'>View Number</button> */}


                      {!token && (
                        <>
                          <button className='view-number-btn'>
                            <Link to="/login">
                            <CiPhone />View Number</Link>
                          </button>

                        </>
                      )}

                      {userDetails && (


                        <Link to='tel:{data.phone_number}' className='view-number-btn flex align-center'>
                          <FaPhoneAlt className='text-red text-[20px] font-semibold' />{data.vendor_details.phone_number}</Link>


                      )}




                    </div>
                  </div>
                </div>
               
              
                {/* <h2 className='font-normal mt-4 text-[#000000] font-poppins'>{data.short_description}</h2> */}




              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className='right-sidebar'>
        <img src={advt} />
      </div>
    </div>
  );
}

export default App;
