import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import logo from '../assests/img/logo.png';
import { NavLink, Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer'

const Login = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorText('');
    if (input.email == "" || input.password == "") {
      setErrorText('Please fill all the fields');
      return;
    }
    if (loading) return;
    setLoading(true);
    const loginUrl = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/customerlogin?email=${input.email}&password=${input.password}`;
    var response = await fetch(loginUrl);
    var data = await response.json();
    if (data.status == 'true') {
      const headers = {
        'Authorization': data.data.token
      }
      const customerDetailsUrl = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/customer_details`;
      var customerDetailsResponse = await fetch(customerDetailsUrl, { headers: headers });
      var customerDetails = await customerDetailsResponse.json();
      if (customerDetails.message == 'Success') {
        localStorage.setItem('user', JSON.stringify(customerDetails.data));
        localStorage.setItem('token', data.data.token);
        // navigate("/")
        const redirectTo = location.state?.from || '/';
        navigate(redirectTo);
      } else {
        setErrorText("something went wrong please try again!")
      }
    } else {
      setErrorText(data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className='login-container'>
        {/* <div className='login-content login-content-col'>
                <a href="/">
                  <img src={logo} alt="logo" className='w-[150px]' />
                </a>

                <p className="login-txt">Welcome! to Made in timor-leste Portal.You can login with your credentials and request for the services Provided by MCI For any Help please contact on :</p>

                <div className='contact-row'>
                <p class=""><a href="tel:&nbsp;+670-77777777">&nbsp;+670-77777777</a></p>
                </div>

                <div className='email-row'>
                <p class=""><a href="mailto:info@madeintimor-leste.com">info@madeintimor-leste.com</a></p>
                </div>
                </div> */}



        <div className='login-form-box'>
          <form onSubmit={handleLogin} className='flex flex-col gap-7 rounded-md'>
            <h1 className='login-title'>Login</h1>
            <input name='email' value={input.email} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="email" placeholder='Enter your Email' className='input-box' />
            <input name='password' value={input.password} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="Password" placeholder='Enter your password' className='input-box' />
            <span className='text-red-500'>{errorText}</span>
            <button type='submit' className='login-btn'>{loading ? 'Loading..' : 'Login'}</button>
            <span className='register-link-box'>Don't have an account <Link to="/register" className='font-bold text-blue-800'>Register</Link></span>
            <span className='register-link-box'><Link to="/forget-password" className='font-bold text-blue-800'>Forget Password</Link></span>
          </form>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default Login