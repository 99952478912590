import React, { useState, useEffect } from 'react';
import Category from './Category';

function CategoryList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/category_list')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data:', data); // Log the fetched data
        if (Array.isArray(data.data)) {
          setData(data.data);
        } else {
          throw new Error('Fetched data is not an array');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (

    <div className=" bg-white sidebar-cat-container rounded-md ">
      <h1 className='text-left text-[18px] text-[#082f4a] font-bold mb-4  top-cat-title'>Categories</h1>
      <ul className='px-4 category-list text-[14px]  text-[#4f5f6d] text-left py-4 main-ul'>
        {data.map(category => (
          <Category key={category.category_id} category={category} />
        ))}
      </ul>
      <a href="#" className='view-all-btn text-center'>View All Category</a>
    </div>
  );
}

export default CategoryList;
