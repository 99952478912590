import React from 'react'
import Header from '../Components/Header';
import BannerSection from '../Components/BannerSection';
import FeaturePrd from '../Components/FeaturePrd';
import CatlogSlider from '../Components/CatlogSlider';
import NewArrival from '../Components/NewArrival';
import Trusted from '../Components/Trusted';
import SocialBar from '../Components/SocialBar';
import Footer from '../Components/Footer'
import PopularCategory from '../Components/PopularCategory';
import PostYourRequirment from '../Components/PostYourRequirment';

function App() {
return (
    <div >
   
<Header/>


<div className='top-banner-container container'>
<BannerSection/>
</div>

{/* <div className='popular-category-row'>
    <div className='container'>
    <PopularCategory/><br/><br/>
    <PopularCategory/>   
    </div>
</div> */}


<div className='enquiry-row'>
    <div className='container'>
    <PostYourRequirment/> 
    </div>
</div>


<div className='w-full py-8 lg:max-w-7xl lg:px-4 xl:max-w-[1600px] justify-between grid grid-cols-12  gap-8 mx-auto'>
<div className='col-span-6 p-4 new-arrival-row'>
<h3 className='text-left text-[18px] font-poppins text-[#082f4a] font-[500]'>New Arrivals</h3>
<h3 className='text-left text-[14px] font-poppins pb-4 text-[#5e7384] font-normal'>
Buy from an exciting collection of our newly added products</h3>

<NewArrival/>
</div>
<div className='w-full col-span-6 new-arrival-row'>
<h3 className='text-left text-[18px] font-poppins text-[#082f4a] font-[500]'>TradIndia Trusted</h3>
<h3 className='text-left text-[14px] font-poppins text-[#5e7384] pb-4 font-normal'>
Buy products from over 4.5 million verified and trusted TradeIndia sellers</h3>
<Trusted/>

</div>
</div>


<div className='w-full mt-4 shadow rounded-lg drop-shadow-[0px 2px 30px 9px rgba(0, 0, 0, 0.1)] p-8 lg:max-w-7xl lg:px-4 xl:max-w-[1600px] mx-auto'>
<h3 className='text-left text-[18px] font-poppins text-[#082f4a] font-[500]'>Trending Categories</h3>
<CatlogSlider/>
</div>



<div>

</div>
<div className='px-4 w-full' >
<Footer/>
</div>



 </div>
)

}

export default App;
