import React from 'react';
import SideBar from './SideBar';
import NavBar from './NavBar';
import banner from '../assests/img/image03.png';
import banner1 from '../assests/img/image04.png';
import SidebarMenu from './SideMenu';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import icn1 from '../assests/img/home-applice.jpg'
import icn2 from '../assests/img/light.jpg'
import icn3 from '../assests/img/tool.jpg'
import icn4 from '../assests/img/michnery.jpg'
import icn5 from '../assests/img/stone.jpg'
import icn6 from '../assests/img/cctv.jpg'

import icn9 from '../assests/img/11.png'
import icn10 from '../assests/img/10.png'
import icn11 from '../assests/img/09.png'
import icn12 from '../assests/img/98.png'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const Trusted = () => {
  return (
    <div>
       <div className="w-full  ">
            <Carousel responsive={responsive}
             infinite={false}>
              
              <div>
              <div className='block m-2 text-center border pt-4 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn1} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Home Appliances</h4>       
             </div>
              </div>

              <div>  <div className='block m-2 text-center border pt-4  rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn2} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Lights and Lighting</h4>       
             </div></div>

              <div>  <div className='block m-2 text-center border pt-4  rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn3} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Machinery and Tools</h4>       
             </div></div>

              <div> 
              <div className='block m-2  text-center border pt-4 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn4} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Minerals and Metallurgy</h4>       
             </div>
                </div> 

              <div><div className='block m-2 text-center border pt-4 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn5} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Natural Stone</h4>       
             </div></div> 

             <div>
              <div className='block m-2 text-center border pt-4 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={icn6} alt='hair-accessories'/></div>           
            <h4 className='text-left p-2 text-[14px] text-[#2d3840]'>Security and Protection </h4>    
             </div>
              </div>

            </Carousel>
           
          </div>
    </div>
  )
}

export default Trusted
