import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import logo from '../assests/img/logo.png';
import { NavLink, Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer'

const ForgetPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [input, setInput] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorText('');
    if (input.email == "" ) {
      setErrorText('Please fill all the fields');
      return;
    }
    if (loading) return;
    setLoading(true);
    const loginUrl = `https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/forgetPasswrod?email=${input.email}`;
    var response = await fetch(loginUrl);
    var data = await response.json();
    if (data.status == 'true') {
 
    } else {
      setErrorText(data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className='login-container'>
        {/* <div className='login-content login-content-col'>
                <a href="/">
                  <img src={logo} alt="logo" className='w-[150px]' />
                </a>

                <p className="login-txt">Welcome! to Made in timor-leste Portal.You can login with your credentials and request for the services Provided by MCI For any Help please contact on :</p>

                <div className='contact-row'>
                <p class=""><a href="tel:&nbsp;+670-77777777">&nbsp;+670-77777777</a></p>
                </div>

                <div className='email-row'>
                <p class=""><a href="mailto:info@madeintimor-leste.com">info@madeintimor-leste.com</a></p>
                </div>
                </div> */}



        <div className='login-form-box'>
          <form onSubmit={handleSubmit} className='flex flex-col gap-7 rounded-md'>
            <h1 className='login-title'>Forget Password</h1>
            <input name='email' value={input.email} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="email" placeholder='Enter your Email' className='input-box' />
            <span className='text-red-500'>{errorText}</span>
            <button type='submit' className='login-btn'>{loading ? 'Loading..' : 'Submit'}</button>
          </form>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ForgetPassword