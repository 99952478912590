import React, { useState, useEffect } from 'react';
import f01 from '../assests/img/17.png'
import f02 from '../assests/img/18.png'
import f03 from '../assests/img/19.png'
import f04 from '../assests/img/22.png'
import f05 from '../assests/img/23.png'
import f06 from '../assests/img/20.png'
import InquiryDialog from './InquireyDialog';
import { Navigate, useNavigate } from 'react-router-dom';


const ProductShow = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProductData] = useState();
  const [submitted, setSubmitted] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    let productListUrl = 'https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/productlist';
    fetch(productListUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data:', data); // Log the fetched data
        if (Array.isArray(data.data)) {
          setData(data.data);
        } else {
          throw new Error('Fetched data is not an array');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleProductClick = (product) => {
    navigate('/product-details/' + product.product_slug);
  }
  const checkIfUserIsLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  }

  const handleEnquiryClick = (product) => { 
    const isLoggedIn = checkIfUserIsLoggedIn();
    console.log('isLoggedIn', isLoggedIn);  
    if (isLoggedIn) {
      console.log('product 61', product);
      setCurrentProductData(product);

      onOpenModal();
    } else {

      navigate('/login', { state: { from: window.location.pathname, product } });

      // navigate('/login',  { state: { from: '/products'} } );

    }
  }



  return (
    <div className='related-product-container container'>
       <h4 className='related-title'>Find Similar Products</h4>
          <div className='pro-contant'>
             {data.map((item, index) => (
           <div className='product-card'>
           <div onClick={()=> handleProductClick(item)} className='product-inner'>
           <div className='pro-img'> 
           <img src={item.featured_image} alt='hair-accessories'/></div> 
           <div className='font-bold text-sm leading-6'>            
            <h4 className='pro-name'>{item.title}</h4> 
            {/* <h4 className='pro-price'>{`Rs- ${item.sale_price != ''? item.regular_price: 0}`}</h4>  */}
            {/* <h4 className='pro-price'>Rs.{item.regular_price}</h4>  */}
             </div>
          
             </div>
             </div>
      ))}

             {/* <div className='col-span-3 text-center bg-white border pt-4 p-2 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={f01} alt='hair-accessories'/></div>  
           <div className='h-[100px] font-bold text-sm leading-6'>         
            <h4 className='text-center font-poppins font-normal text-black text-[16px]'>Briquette Crusher</h4> 
            <h4 className='text-center font-poppins font-normal text-black text-[16px]'>1100000 INR(Approx)</h4> 
           
            <h4 className='text-center pt-2 text-black text-sm font-normal'>Ecoman</h4> </div>   
            <button className='my-2 rounded-md  cursor-pointer mt-4 bg-[#4084ad] h-[48px] max-w-[182px] w-full'> <a href='#' className=' text-white font-poppins '>Send Inquiry</a></button>    
             </div> */}
             {/* <div className='col-span-3 text-center bg-white border pt-4 p-2 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={f03} alt='hair-accessories'/></div>    
           <div className='h-[100px] font-bold text-sm leading-6'>        
            <h4 className='text-center font-poppins font-normal text-black text-[16px]'>
              Eco-Freindly 99.9% Pure A Grade Ammonium Carbonate
            </h4> 
            <h4 className='text-center font-poppins font-normal text-black text-[16px]'>33 INR(Approx)</h4> 
              
            <h4 className='text-center pt-2 text-black text-sm font-normal'>
              DESTINY CHEMICALS</h4>  </div>
              <button className='my-2 rounded-md  cursor-pointer mt-4 bg-[#4084ad] h-[48px] max-w-[182px] w-full'> <a href='#' className=' text-white font-poppins  '>Send Inquiry</a></button>  
             </div> */}
             {/* <div className='col-span-3 text-center bg-white border pt-4 p-2 rounded-md'>
           <div className='border text-center flex h-[100px] justify-center  pb-4   border-b-gray-200 border-l-0 border-r-0 border-t-0'> 
           <img src={f04} alt='hair-accessories'/></div> 
           <div className='h-[100px] font-bold text-sm leading-6'>          
            <h4 className='text-center font-poppins font-normal text-black text-[16px]'>Top Rate Epoxy Hardener</h4> 
            <h4 className='text-center  text-gray-500'></h4> 
            <h4 className='text-center text-[#5E7384] font-poppins font-normal text-sm'></h4>    
            <h4 className='text-center pt-2 text-black text-sm font-normal'>NOVEL CHEM</h4>  </div>
            <button className='my-2 rounded-md  cursor-pointer mt-4 bg-[#4084ad] h-[48px] max-w-[182px] w-full'> <a href='#' className=' text-white font-poppins '>Send Inquiry</a></button>   
             </div> */}
            
          
          
           
          

           
           
          
          
      


     </div>
     <InquiryDialog open={open} onCloseModal={onCloseModal} title={data.title} submitted={submitted} currentProduct={currentProduct} />

     {/* <InquiryDialog open={open} onCloseModal={onCloseModal}  title = {data.title} submitted= {submitted} currentProduct={currentProduct} /> */}

    </div>
  )
}

export default ProductShow
