import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaPhone, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import InquiryDialog from './InquireyDialog';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";


function ProductDetail(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProductData] = useState();
  const user = localStorage.getItem('user');
  const userDetails = user ? JSON.parse(user) : null;
  console.log('user details', userDetails);
  const token = localStorage.getItem('token');



  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();





  useEffect(() => {
    let productDetailUrl = 'https://femonia.com/makeittomer/wp-json/makeittomer-api/v1/productDetails';
    if (props.slug) {
      productDetailUrl = productDetailUrl + `?product_slug=${props.slug}`;
    }
    fetch(productDetailUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data new:', data); // Log the fetched data
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [props.slug]);

  // Function to check if the user is logged in
  const checkIfUserIsLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  }

  // Function to handle the enquiry button click
  const handleEnquiryClick = (product) => {
    const isLoggedIn = checkIfUserIsLoggedIn();
    if (isLoggedIn) {
      setCurrentProductData(product);
      onOpenModal();
    } else {
      navigate('/login', { state: { from: window.location.pathname, product } });

      // navigate('/login', { state: { from: '/product-details/' + product.product_slug } });

    }
  }

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };




  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="product-detail-content container">

      <ul className='post'>
        {
          <li key={data.slug} className='w-full'>
            <div className='product-detail-containter'>
              <div className='product-media'>
                <div className='media-inner-box'>  <img onClick={(e) => openImageInNewTab(data.product_image_url)} src={data.product_image_url} className='w-full' /> </div>

                <div className='photo-gallery'>
                  <ul>
                    {data.gallery_image.map((item, index) => (
                      <li><img onClick={(e) => openImageInNewTab(item)} src={item} className='pro-thumb' /> </li>
                    ))}
                  </ul>
                </div>

              </div>
              <div className='product-info'>

                <div className='product-detail-left'>
                  <h3 className='product-title-name-inner'>{data.product_name}</h3>
                  {!token && (
                    <>
                      <h4 className='price-box'><a href="#" className='btn btn-latest-price'>Get Latest Price</a></h4>
                    </>
                  )}

                  {userDetails && (

                    <h4 className='price-box'><a href="#" className='btn btn-latest-price'>Rs. {data.product_price}</a></h4>

                  )}


                  <div className='prod-attri-box'>
                  <h2>Product Details</h2>
                  <div className="attribute-box tabledesc mt10">
                  <table>
                    {data.attributes.length > 0 &&
                      (   
                        <tbody>
                            {data.attributes.map((item, index) => (
                              <tr><td class="tdwdt">{item.name}</td><td class="tdwdt1 color6"><div class="dsf"><span> {item.option}</span></div></td></tr>
                            ))}
                            </tbody>
                      )
                    }
                  </table>
                  </div>
                  </div>
                  <div className="seller-row">  

                       <div className='contact-seller-row'>
                        <button onClick={(e) => handleEnquiryClick(data)} className='send-inq-btn'>
                        <CiMail />
                        Contact Now
                        </button>

                        <InquiryDialog open={open} onCloseModal={onCloseModal} title={data.product_name} currentProduct={currentProduct} />




                        {!token && (
                          <>
                            <button className='view-number-btn'>
                              <Link to="/login"> <CiPhone />View Number</Link>
                            </button>
                          </>
                        )}

                        {userDetails && (
                          <Link to='tel:{data.phone_number}' className='view-number-btn flex align-center'>
                            <FaPhoneAlt className='text-red text-[20px] font-semibold' />{data.phone_number}</Link>
                        )}
                      </div> 
                  </div>

                  <div className='short-description-box' dangerouslySetInnerHTML={{ __html: data.product_short_description }} />
                </div>
              </div>
            </div>
          </li>
        }
      </ul>
      <div className='pro-tab-container container'>
      <div className='tab-container'>
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Product Description</Tab>
            <Tab>Details Photos</Tab>
          </TabList>

          <TabPanel>
          <div className='tab-description-content' dangerouslySetInnerHTML={{ __html: data.product_description }} />
          </TabPanel>
          <TabPanel>
          <div className="attribute-box tabledesc mt10">
                  <table>
                    {data.attributes.length > 0 &&
                      (   
                        <tbody>
                            {data.attributes.map((item, index) => (
                              <tr><td class="tdwdt">{item.name}</td><td class="tdwdt1 color6"><div class="dsf"><span> {item.option}</span></div></td></tr>
                            ))}
                            </tbody>
                      )
                    }
                  </table>
                  </div>
          </TabPanel>
          <TabPanel>
          <div className='details-photo'>
                  <ul>
                    {data.gallery_image.map((item, index) => (
                      <li><img onClick={(e) => openImageInNewTab(item)} src={item} className='pro-thumb' /> </li>
                    ))}
                  </ul>
                </div>
          </TabPanel>
        </Tabs>

</div>
        <div className='seller-box'>
                      <a href="#" className='seller-name flex'><img src={data.logo} /><span>{data.store_name}</span></a>
                      <div className='seller-address'> {data.address_1}, {data.address_2} {data.city}, {data.postcode}, India</div>
                      <div className='gst-number'>GST/TIN - {data.tin_number}</div>
                      <div className='trust-seller'><img src="https://www.tradeindia.com/_next/image/?url=%2Fimages%2Ficons%2Ftrusted-seller-new.png&w=1920&q=75" />
                        <span class="trusted-text"><strong>Trusted</strong>Seller</span></div>

                      <div className='contact-seller-row'>
                        <button onClick={(e) => handleEnquiryClick(data)} className='send-inq-btn'>Send Inquiry</button>
                        <InquiryDialog open={open} onCloseModal={onCloseModal} title={data.product_name} currentProduct={currentProduct} />




                        {!token && (
                          <>
                            <button className='view-number-btn'>
                              <Link to="/login">View Number</Link>
                            </button>

                          </>
                        )}

                        {userDetails && (


                          <Link to='tel:{data.phone_number}' className='view-number-btn flex align-center'>
                            <FaPhoneAlt className='text-red text-[20px] font-semibold' />{data.phone_number}</Link>


                        )}


                      </div>
                    </div>

      </div>
    </div>

  );
}

export default ProductDetail;
